const homedata=[
    {
        title :"mergesort",
         cName:"merge-sort",
         buttonText:"MERGE SORT",
         url:"/mergesort",
         Cname:"mergr-button"
    },
    {
        title :"quicksort",
         cName:"quick-sort",
         buttonText:"QUICK SORT",
         url:"/quicksort",
         Cname:"quick-button"
    },
    {
        title :"selectionsort",
         cName:"selection-sort",
         buttonText:"Selection SORT",
         url:"/selectionsort",
         Cname:"selection-button"
    },
    {
        title :"bubblesort",
         cName:"bubble-sort",
         buttonText:"BUBBLE SORT",
         url:"/bubblesort",
         Cname:"bubble-button"
    }
]
export default homedata